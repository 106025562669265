<template>
    <div class="baseMain">
        <div class="baseSearch clearfloat">
            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>批次开始时间：</span>
                </div>
                <div class="baseSearchOneRight3">
                    <el-date-picker v-model="searchForm.time" type="datetimerange" range-separator="-"
                                    start-placeholder="请选择开始范围" end-placeholder="请选择结束范围"
                                    value-format="YYYY-MM-DD HH:mm:ss"></el-date-picker>
                </div>
            </div>
            <div class="baseSearchOne">
                <el-button type="primary" style="margin-left: 20px;" @click="getTableData">搜索</el-button>
                <el-button @click="cleanAll">清空</el-button>
            </div>
        </div>
        <div class="baseOperate clearfloat">
            <div class="baseOperateOne tableButton">
                <el-button type="primary" @click="openExport">导出全部</el-button>
            </div>
        </div>
        <div class="baseTable" v-loading="tableLoading">
            <publicTable :publicTableOtherHeight="searchHeight" :publicTableData="tableData"
                         :publicTableConfigure="tableConfigure">
                <el-table-column prop="plan_name" label="计划名称"></el-table-column>
                <el-table-column prop="batch_name" label="批次名称"></el-table-column>
                <el-table-column prop="number" label="招生人数"></el-table-column>
                <el-table-column prop="sign_count" label="报名人数"></el-table-column>
                <el-table-column prop="inscription_count" label="预录取人数"></el-table-column>
                <el-table-column prop="admission_count" label="录取人数"></el-table-column>
                <el-table-column label="录取男生/女生">
                    <template #default="scope">
                        <span>{{scope.row.man_count}} / {{scope.row.woman_count}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="drop_count" label="退学人数"></el-table-column>
            </publicTable>
        </div>
    </div>
</template>

<script>

import publicTable from '../../components/publicTable.vue'

import {businessGet} from '../../api/business.js'

import {number, bignumber, add} from 'mathjs'

import XLSX from "xlsx";
import moment from 'moment'

export default {
    name: "historyStatistics",
    components: {
        publicTable
    },
    data() {
        return {
            searchForm: {
                time: [],
            },
            activeName: '0',
            searchHeight: 0,
            tableLoading: false,
            tableData: [],
            tableConfigure: {
                // showSummary: true,
                border: true
            },
        }
    },
    mounted() {
        this.$nextTick(() => {
            let baseSearchDom = document.querySelector('.baseSearch');
            let baseOperateDom = document.querySelector('.baseOperate');
            let searchHeight = baseSearchDom.offsetHeight + baseOperateDom.offsetHeight - 38;
            this.searchHeight = searchHeight;
            this.getTableData()
        })
    },
    methods: {
        exportExcel() {
            let wbDom = document.querySelector('.el-table__header tr')
            let wsDom = document.querySelector('.el-table__body')
            wsDom.querySelector('tbody').prepend(wbDom)
            var newWs = XLSX.utils.table_to_book(wsDom, {raw: true})
            let exportName = "报名统计.xlsx"
            XLSX.writeFile(newWs, exportName);
        },
        cleanAll() {
            var year = moment().format("YYYY")
            this.searchForm.time = [`${year}-01-01`,`${year}-12-31`]
            this.getTableData();
        },
        openExport() {
            this.exportExcel();
        },
        getTableData() {
            let url = '/admin/v1/sign_up/statistics?type=-1';
            for (let k in this.searchForm) {
                if (this.searchForm[k]) {
                    if (k == 'time') {
                        if (this.searchForm[k][0] && this.searchForm[k][1]) {
                            url = url + '&' + k + '=' + this.searchForm[k][0] + ' - ' + this.searchForm[k][1];
                        }
                    }
                    else {
                        url = url + '&' + k + '=' + this.searchForm[k];
                    }
                }
            }
            this.tableLoading = true;
            businessGet(url).then(res => {
                this.tableLoading = false;
                if (res.data.status == 200) {
                    let list = res.data.data.list;
                    let tableData = [];
                    list.forEach(item => {
                        if (item.child.length > 0) {
                            let number_total = 0
                            let sign_count_total = 0
                            let inscription_count_total = 0
                            let admission_count_total = 0
                            let man_count_total = 0
                            let woman_count_total = 0
                            let drop_count_total = 0
                            item.child.forEach((son, index) => {
                                let one = son;
                                number_total = number(add(bignumber(number_total), bignumber(one.number)))
                                sign_count_total = number(add(bignumber(sign_count_total), bignumber(one.sign_count)))
                                inscription_count_total = number(add(bignumber(inscription_count_total), bignumber(one.inscription_count)))
                                admission_count_total = number(add(bignumber(admission_count_total), bignumber(one.admission_count)))
                                man_count_total = number(add(bignumber(man_count_total), bignumber(one.man_count)))
                                woman_count_total = number(add(bignumber(woman_count_total), bignumber(one.woman_count)))
                                drop_count_total = number(add(bignumber(drop_count_total), bignumber(one.drop_count)))
                                one.plan_id = item.plan_id;
                                one.plan_name = item.name;
                                if (index == 0) {
                                    one.mergeObj = {
                                        rowspan: item.child.length + 1,
                                        colspan: 1,
                                    }
                                }
                                else {
                                    one.mergeObj = {
                                        rowspan: 0,
                                        colspan: 0,
                                    }
                                }
                                tableData.push(one)
                            })
                            let oneTotal = {
                                plan_id: item.plan_id,
                                plan_name: item.name,
                                admission_count: admission_count_total,
                                batch_name: "合计",
                                batch_no: "合计" + item.plan_id,
                                drop_count: drop_count_total,
                                inscription_count: inscription_count_total,
                                is_pre_inscription: 1,
                                man_count: man_count_total,
                                number: number_total,
                                sign_count: sign_count_total,
                                woman_count: woman_count_total,
                                mergeObj: {
                                    rowspan: 0,
                                    colspan: 0,
                                }
                            }
                            tableData.push(oneTotal)
                        }
                    })
                    this.tableData = tableData;
                }
                else {
                    this.$message({
                        showClose: true,
                        message: res.data.message,
                        type: 'warning'
                    });
                }
            })
        },
    }
}
</script>

<style scoped>

@import "../../assets/css/base.css";

</style>
